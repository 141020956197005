import type { HTMLAttributes, ReactNode } from 'react';
import { useMemo, forwardRef } from 'react';
import { CloseOutlined } from '@packages/icons-react';
import { cn } from 'class-merge';
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useFloating,
} from '@floating-ui/react';
import { mergeRefs } from 'react-merge-refs';
import { useTheme } from '~contexts/theme';
import getDialogStyle from '~libs/components/get-dialog-style';

export interface DialogProps extends HTMLAttributes<HTMLDivElement> {
  open: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  maskable?: boolean;
  closable?: boolean;
  className?: string;
  overlayClassName?: string;
  children: ReactNode;
  onClose: () => void;
}

const Dialog = forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      open,
      size,
      className,
      overlayClassName,
      children,
      closable,
      maskable,
      onClose,
    },
    ref,
  ) => {
    const { dialog } = useTheme();
    const { backdropClasses, dialogClasses, dialogBodyClasses } =
      getDialogStyle({
        size,
        className,
        ...dialog,
      });

    const { floating, context } = useFloating({
      open,
    });

    const mergedRef = useMemo(
      () => mergeRefs([ref, floating]),
      [floating, ref],
    );

    const handleCloseMaskable = (): void => {
      if (!maskable) return;
      onClose();
    };

    return (
      <FloatingPortal>
        {open ? (
          <FloatingOverlay
            lockScroll
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            style={{
              zIndex: 50,
              overflow: 'hidden',
            }}
          >
            <FloatingFocusManager context={context}>
              <div className="scrollbar-hidden relative flex h-screen w-screen items-center justify-center overflow-y-scroll bg-black bg-opacity-60">
                <div
                  aria-hidden
                  className={cn(
                    'animate-fade-in cursor-default',
                    size === 'xxl' ? '' : backdropClasses,
                  )}
                  onClick={handleCloseMaskable}
                  role="button"
                />
                <div
                  className={cn(dialogClasses, overlayClassName)}
                  ref={mergedRef}
                >
                  <div className={dialogBodyClasses}>
                    {closable ? (
                      <div
                        aria-hidden
                        className="absolute -right-2 top-2 flex h-[25px] w-[25px] cursor-pointer items-center justify-center rounded-full bg-white text-sm text-black sm:-right-3 sm:-top-4"
                        onClick={onClose}
                        role="button"
                      >
                        <CloseOutlined />
                      </div>
                    ) : null}
                    {children}
                  </div>
                </div>
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        ) : null}
      </FloatingPortal>
    );
  },
);

Dialog.displayName = 'Dialog';

export { Dialog };
