'use client';

import { useEffect, useState } from 'react';
import { parseCookies } from 'nookies';

const useAuth = (): { isLoggedIn: boolean } => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const cookies = parseCookies();
    const accessToken = cookies.access_token;

    if (accessToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return { isLoggedIn };
};

export default useAuth;
