import { useSession } from 'next-auth/react';
import { useMemo } from 'react';
import type {
  BankTransferPaymentInfo,
  PaymentMethodDetail,
} from 'types/financial';
import type { UserInfoInterface } from '../types/user';
import type { Return } from '~hooks/use-request';
import useRequest from '~hooks/use-request';
import getDisplayName from '~libs/get-display-name';
import { useAgent } from '~contexts/agent';
import useAuth from '~hooks/use-auth';

export const useUserInfo = (): Return<UserInfoInterface, unknown> & {
  displayName: string;
} => {
  const { data: session } = useSession();
  const { isLoggedIn } = useAuth();
  const { agentInfo } = useAgent();
  const { data: userInfo, ...rest } = useRequest(
    isLoggedIn
      ? {
          url: '/users/me',
        }
      : undefined,
    {
      initialData: session?.user,
    },
  );
  const displayName = useMemo(() => {
    return getDisplayName(userInfo?.username, agentInfo?.prefixUsername);
  }, [userInfo?.username, agentInfo?.prefixUsername]);
  return {
    data: userInfo,
    displayName,
    ...rest,
  };
};

export const useUserPaymentMethods = (): Return<
  PaymentMethodDetail<BankTransferPaymentInfo>[],
  unknown
> => {
  return useRequest<PaymentMethodDetail<BankTransferPaymentInfo>[]>({
    method: 'GET',
    url: '/users/payment-methods',
  });
};
