'use client';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LazyImportPlugin from './lazy-load-plug-in-i18n';
import { DEFAULT_LANG, FALLBACK_LANG, LanguageEnum } from '~constants/etc';

void i18n
  .use(initReactI18next)
  .use(LazyImportPlugin)
  .init({
    lng: DEFAULT_LANG,
    fallbackLng: FALLBACK_LANG,
    supportedLngs: [LanguageEnum.EN, LanguageEnum.VI],
    debug: false,
    load: 'languageOnly',
    preload: [],
    ns: ['common', 'errors'],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
