import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '~components/button';
import { ButtonColors } from '~constants/components';

const UnauthorizedAlertWidget: FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const { t } = useTranslation(['buttons', 'errors']);
  return (
    <div className="bg-dark w-[350px] rounded-xl p-4">
      <p className="text-lg font-semibold">{t('errors:notification-system')}</p>
      {t('errors:unauthorized-text')}
      <Button
        className="mt-2 w-full"
        color={ButtonColors.Danger}
        onClick={onClose}
      >
        {t('buttons:btn-close')}
      </Button>
    </div>
  );
};

export default UnauthorizedAlertWidget;
