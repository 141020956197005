import type { ButtonStylesType } from 'themes/components/button';
import type { ButtonProps } from '~components/button';
import { ButtonColors, ButtonVariants, SizeTypes } from '~constants/components';
import { objectsToString } from '~libs/objects-to-string';

const getButtonStyles = (
  button: ButtonProps & ButtonStylesType,
): {
  buttonBlock: string | false | undefined;
  buttonBase: string;
  buttonLoading: string;
  buttonSize: string;
  buttonVariant: string;
  buttonLoadingAnimation: string;
} => {
  const { styles, defaultProps } = button;
  let { size, color, variant, block } = button;
  const { base, variants, sizes } = styles;

  color = color || defaultProps?.color;
  variant = variant || defaultProps?.variant;
  size = size || defaultProps?.size;
  block = block || defaultProps?.block;

  const buttonBase: string = objectsToString(base.initial);
  const buttonLoading: string = objectsToString(base.loading);
  const buttonLoadingAnimation: string = objectsToString(base.loadingAnimation);
  const buttonBlock: string = objectsToString(base.block);
  const buttonSize: string = objectsToString(sizes[size || SizeTypes.Medium]);
  const buttonVariant: string = objectsToString(
    variants[variant || ButtonVariants.Fill][color || ButtonColors.Primary],
  );

  return {
    buttonBlock: block && buttonBlock,
    buttonBase,
    buttonLoading,
    buttonSize,
    buttonVariant,
    buttonLoadingAnimation,
  };
};

export default getButtonStyles;
